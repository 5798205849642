import * as React from 'react';
import Layout from '../components/layout';
import Seo from '../components/seo';

const IndexPage = () => {
  return (
    <Layout>
      <Seo title="Accueil" />
      <main className="flex justify-center py-12">
        <h1 className="mt-4 text-5xl font-extrabold">Accueil | Ndiémé Sow</h1>
      </main>
    </Layout>
  );
};

export default IndexPage;
